import CreatePayDetail from '@/components/ServiceApply'
import CreateHeader from '@/components/ServiceApply/createHeader'
import { Modal,Message, Button} from '@/arco'
import valid from '@/utils/valid'
import language from '@/utils/language'
export function applyDialog({ title = "服务申请", width = 540, submit, callback }) {
    let params = {};
    let confirm = Modal({//全局修改标识
        title: <CreateHeader title={title} />,
        content: <CreatePayDetail onChange={(data) => {
            params = data;
        }} />,
        // className: "cardMarketDialog",
        className:'swiopenDialog specialHeightForApply',
        footer:<div style={{width:"100%"}}><Button children={language('public79')} style={{margin: "0 auto", width: 168}} size='large' onClick={async () => {
            if (!params.phone) {
                Message.error(language('user20'))
                return;
            } else if (!valid(params.phone, 9)) {
                // Message.error(language('market33'))
                return;
            }
            if (params.email && !valid(params.email, 5)) {
                // Message.error(language('market35'))
                return;
            }
            const result = await submit({ ...params});
            Message.success(result.result.msg);
            confirm.close();
            if (callback) {
                callback()
            }
            // props.callback();
        }}/></div>
    })
}