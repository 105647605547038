import React, { useEffect, useState, useRef } from 'react'
import { Popover } from '@arco-design/web-react';
import cs from 'classnames'
import './index.less'
import language from '@/utils/language'
export default function NewPopover(props) {
  const parent = useRef(null);
  const child = useRef(null);
  const [isDisabled, setIsDisabled] = useState(true)
  useEffect(() => {
    setIsDisabled(parent.current.offsetWidth >= child.current.offsetWidth)
  }, [props.title])
  return (
    <Popover
      trigger={props.trigger || "hover"}
      content={props.title}
      disabled={isDisabled}
      className={"customPovoverStyle"}
    >
      <div className={cs("popoverContainer", props.className)} style={{
        ...props.style
      }} onClick={props.onClick} ref={parent}>
        <span ref={child} dangerouslySetInnerHTML={{ __html: props.title || language('treator36') }}/>
      </div>
    </Popover>
  )
}
