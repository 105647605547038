/**
 * 勿删
 * 次文件用于存储一些只能在组件内使用的方法,入useDispatch和useNavigat等
 * 比如react-router-dom中的useNavigate方法，主要fetch.js的拦截器跳转页面，其他组件也可以使用
 * 方法：import eventState from '@/utils/navigate"
 *       eventState.navigate("///")
 *       eventState.dispatch()
 */
import { Button, Modal } from '@/arco'
import Badge from '@/components/Badge'
import { checkOverLimit, checkIsExist } from '@/api/IntelligenceSub/infoSub'
import "@/assets/captcha/tac/tac.css" // 验证码css
import "@/assets/captcha/tac/tac.min.js"; // 验证码js
import logo from "@/assets/captcha/images/logo.png"
import valid from './valid'
import language from '@/utils/language'
const CryptoJS = require('crypto-js');
const eventStation = {
    cutString: function (content, zh = 31, en = 56) {
        // console.log(this.lang);
        if (this.lang == 'zh') {
            const result = content.substring(0, zh) + (content.length > zh ? "..." : "")
            return result;
        } else if (this.lang = "en") {
            const result = content.substring(0, en) + (content.length > en ? "..." : "")
            return result;
        } else {
            return content;
        }
    },
    tagColor: function (color) {
        switch (Number(color)) {
            case 1:
                return 'error';
            case 2:
                return 'blue';
            default:
                return ""
        }
    },
    //检查是否有非法字符
    validChar: (str) => {
        const condition = ['<', '$', '>'];
        let result = false;
        condition.map((item) => {
            if (str.indexOf(item) > -1) {
                result = true;
            }
        })
        return result;
    },
    //检查集合是否重名
    nameIsExist: async () => {

    },
    //检查是否超出上限
    isOverLimit: async (type = 1) => {
        /**
         * type:1=>集合
         * type:2=>传感器
         */
        const { data: { overLimit, limitNum } } = await checkOverLimit({ type })
        if (overLimit == 1) {
            // if (eventStation.confirm) {
            //     eventStation.confirm.close();
            // }
            const confirm = Modal({
                title: "创建失败",
                content: `传感器最多支持创建 ${limitNum} 个，您已超限，如遇问题请您与我们联系。`,
                style: { width: 460 },
                type: 'alert',
                closable: false,
                maskClosable: false,
                footer: <Button children="关闭" onClick={() => {
                    eventStation.navigate('/subscription/info-sub/list')
                    confirm.close()
                }} />
            })
            return { pass: false, max: limitNum };
        } else {
            return { pass: true };
        }
    },
    //检查是否重名
    checkNameIsExist: async (name, type, id) => {
        //1：集合 2：传感器
        if (name) {
            const { data: { isExist } } = await checkIsExist({
                name,
                type,
                id
            });
            if (isExist == 1) {
                return false;
            } else {
                return true;
            }
        } else {
            return false;
        }
    },
    safeHtml: (htmlString, empty) => {
        if (htmlString) {
            let safeHtmlString = htmlString.replace(/<em>/g, 'TIANJISIGNSTART').replace(/<\/em>/g, 'TIANJISIGNEND');
            safeHtmlString = safeHtmlString.replace(/</g, '&lt;').replace(/>/g, '&gt;');
            safeHtmlString = safeHtmlString.replace(/TIANJISIGNSTART/g, '<em>').replace(/TIANJISIGNEND/g, '</em>');
            return safeHtmlString
        } else {
            return empty || ""
        }
    },
    checkTrim(str) {
        const res = str.trim();
        return res.length
    },
    //开启图片验证码
    showcaptcha(keyword) {
        const style = {
            // 按钮样式
            btnUrl: "https://minio.tianai.cloud/public/captcha-btn/btn3.png",
            // logo地址
            logoUrl: logo,
            // 滑动边框样式
            // moveTrackMaskBgColor: "#f7b645",
            // moveTrackMaskBorderColor: "#ef9c0d"
        }
        function toIoc() {
            sessionStorage.setItem('iocKeyword', keyword);
            window.open('/home/ioc')
        }
        if (this.userInfo && this.userInfo.loginStatus == 1) {
            toIoc();
        } else {
            const config = {
                requestCaptchaDataUrl: "/gen.json",
                validCaptchaUrl: "/check.json",
                bindEl: "#root",
                // 验证成功回调函数
                validSuccess: (res, c, tac) => {
                    toIoc();
                    tac.destroyWindow();
                },
                validFail: (res, c, tac) => {
                    // 验证失败后重新拉取验证码
                    tac.reloadCaptcha();
                }
            }
            new window.TAC(config, style).init();
        }
    },
    // 判断是否是IOC
    isIoc(value) {
        const icoArr = [1, 2, 3, 4, 5, 6, 7, 10];
        const index = valid(value);
        return icoArr.indexOf(index) > -1
    },
    MD5(str) {
        return CryptoJS.MD5(str).toString()
    },
    //滑动条滚动定位menu列表
    // menuPositionInit(target) {
    //     stop();//初始化之前，先解除绑定事件
    //     const targetInitPosition = {
    //         left: 1
    //     }
    //     const aaa = window.addEventListener('scroll', handleScroll);
    //     console.log(aaa);
    //     function handleScroll() {
    //         console.log(112);
    //     }
    //     function stop() {
    //         console.log(123);
    //         window.removeEventListener('scroll', handleScroll)
    //     }
    //     return {
    //         stop
    //     }
    // }
    menuPositionInit: {
        positionInfo: {
        },
        bottomInfo: {},
        // windowHeight: window.innerHeight,
        scrollTop: 0,
        maxTop: 72,
        positionInit() {
            const { current: { clientWidth, clientHeight, clientTop, clientLeft, offsetLeft, offsetTop } } = this.target;
            const targetInfo = {
                left: clientLeft + offsetLeft,
                top: clientTop + offsetTop,
                width: clientWidth,
                height: clientHeight
            }
            this.positionInfo = { ...targetInfo }
            this.scrollTop = window.scrollY;
        },
        bottomInit() {
            const { current: { clientHeight, clientTop, offsetTop } } = this.bottomLevel;
            this.bottomInfo = {
                top: clientTop + offsetTop,
                height: clientHeight
            }
        },
        init(target, bottomLevel, setTop, setBottom, fixedBottom) {
            this.target = target;
            this.bottomLevel = bottomLevel;
            this.stop();
            this.setTop = setTop;
            this.setBottom = setBottom;
            this.fixedBottom = fixedBottom;
            this.positionInit();
            this.bottomInit();
            window.addEventListener('scroll', this.handleScroll)
            window.menuPositionInit = this;
        },
        handleScroll() {
            menuPositionInit.scrollTop = window.scrollY;
            menuPositionInit.setPosition();
        },
        setPosition() {
            const { positionInfo: { top, height }, scrollTop, maxTop, target, setTop, setBottom, bottomInfo, fixedBottom } = this;
            if (height < innerHeight - this.maxTop) {//整体高度小于窗口高度
                fixedBottom(false)
                if (top - scrollTop > maxTop) {//未超出最高点
                    setTop(false);
                    setBottom(false);
                } else {
                    if (bottomInfo.top + bottomInfo.height - height < scrollTop + this.maxTop) {
                        setTop(false);
                        setBottom(true);
                        // console.log(12);
                    } else {
                        setTop(true);
                        setBottom(false);
                    }
                    // setTop(true);
                    // setBottom(false);
                }
            } else {//整体高度大于窗口高度
                setTop(false)
                setBottom(false)
                if (bottomInfo.top + bottomInfo.height - height < scrollTop - 24) {

                    setBottom(true)
                    fixedBottom(false)
                } else {
                    if (top + height - scrollTop < innerHeight - 12) {
                        fixedBottom(true)
                    } else {
                        fixedBottom(false)
                    }
                }
                // if (bottomInfo.top + bottomInfo.height - height < scrollTop + this.maxTop ) {
                //     console.log(1);
                //     setTop(false);
                //     setBottom(true);
                //     // console.log(12);
                // } else {
                //     setTop(false);
                //     setBottom(false);
                // }
            }
            // console.log(top, height, innerHeight, scrollTop);
            // console.log(top + scrollTop , maxTop);
            // if (top - scrollTop > maxTop) {//未超出最高点
            //     console.log(target.classList);
            // } else {//超出最高点
            //     if (height > innerHeight - maxTop) {//整体高度大于窗口高度 - 最大top

            //     } else {

            //     }
            // }
        },
        stop() {
            window.removeEventListener('scroll', this.handleScroll)
            delete window.menuPositionInit
        }
    },
    createLevel(level) {
        switch (Number(level)) {
            case 1:
                return <Badge type="success" title={language('vulnerability13')} />
            case 2:
                return <Badge type="warning" title={language('vulnerability12')} />
            case 3:
                return <Badge type="error" title={language('vulnerability11')} />
            case 4:
                return <Badge type="error" title={language('vulnerability10')} />
            default:
                return ""
        }
    }
}
export default eventStation;