import request from '@/api/request'


//情报订阅
export const queryCollectionList = params => request('infoSubCollectionListHttp', {params,loading: false})
export const querySensorList = params => request('infoSubSensorListHttp', {params,loading: false})
export const deleteCollection = params => request('infoSubDeleteCollectionHttp', {params})
export const deleteSensor = params => request('infoSubDeleteSensorHttp', {params})
export const tokenRefresh = params => request('infoSubTokenRefreshHttp', {params})
export const queryModifySensorOfCollection = params => request('infoSubModifySensorOfCollectionHttp', {params})
export const createSensorSubmit = params => request('infoSubCreateSensorHttp', {params, returnAll: true})
export const querySensorEditDetail = params => request('infoSubSensorEditDetailHttp', {params})
export const sensorEditSubmit = params => request('infoSubSensorEditSubmitHttp', {params})
export const collectionDetailSubmit = params => request('infoSubCollectionDetailHttp', {params})
export const queryCardListByCollectionDetail = params => request('infoSubCardListByCollectionHttp', {params})
export const queryTiListSubmit = params => request('infoSubTiListByCollectionHttp', {params, loading:false})
export const queryIocListSubmit = params => request('infoSubIocListByCollectionHttp', {params, loading:false})
export const queryInfoListSubmit = params => request('infoSubInfoListHttp', {params})
export const queryIndicactorListSubmit = params => request('infoSubIndicatorListtHttp', {params})
export const querySensorListSubmit = params => request('infoSubSensorLisByCollectiontHttp', {params})
export const querySensorDetailSubmit = params => request('infoSubSensorDetailHttp', {params})
export const queryCardConditionList = params => request('createCardCollectionConditionListHttp', {params})
export const queryCardCollectionList = params => request('createCardCollectionCardListHttp', {params})
export const createCardCollectionSubmit = params => request('createCardCollectionSubmitHttp', {params, returnAll: true})
export const queryCardCollectionDetail = params => request('editCardCollectionQueryDetailHttp', {params})
export const ceditCardCollectionSubmit = params => request('editCardCollectionSubmitHttp', {params, returnAll: true})
export const queryInfoList = params => request('createTagsCollectionInfoListHttp', {params, loading: false})
export const queryCommonTagsAndSource = params => request('createTagsCommonAndSourceHttp', {params})
export const queryTagsByKeyword = params => request('createTagsTagByKeywordHttp', {params,loading:false})
export const createTagsCollectionSubmit = params => request('createTagsCollectionSubmitHttp', {params, returnAll: true})
export const queryTagsCollectionDetail = params => request('editTagsCollectionQueryDetailHttp', {params})
export const queryEditInfoList = params => request('editTagsCollectionInfoListHttp', {params, loading: false})
export const editTagsCollectionSubmit = params => request('editTagsCollectionSubmitHttp', {params, returnAll: true})
export const checkOverLimit = params => request('createBeforeCheckOverLimitHttp', {params})
export const checkIsExist = params => request('createBeforeCheckIsExistHttp', {params,loading: false})
