import TableComponent from './Table'
import DropDownComponent from './DropDown'
import NewPopover from './Popover'
import RedqueenPagination from './Pagination'
import NewButton from './Button'
import NewTag from './Tag'
import {NewInput, NewInputSearch, NewTextarea} from './Input'
import NewRadio from './Radio'
import NewCheckBox from './Checkbox'
import NewCheckBoxSingle from './Checkbox/single'
import NewMessage from './Message'
import {NewRangePicker, NewDatePicker} from './Date'
import NewSelect from './Select'
import NewSwitch from './Switch'
import NewModal from './Modal'
import {NewForm, NewFormItem} from './Form'
import NewAnchor from './Anchor'
import NewLink from './Link'
import NewBadge from './Badge'
import AlertComponent from '@/components/Alert'
import "@arco-design/web-react/dist/css/arco.css";
import { IconLoading } from '@arco-design/web-react/icon'


import Success from '@/assets/success.svg'
import Info from '@/assets/info.svg'
import Warning from '@/assets/warning.svg'
import Error from '@/assets/error.svg'
//table表格
export function Table(props) {
    return <TableComponent {...props} />
}
//下拉框
export function Dropdown(props) {
    return <DropDownComponent {...props}/>
}
//tips提示信息
export function Popover(props) {
    return <NewPopover {...props}/>
}
//分页
export function Pagination(props) {
    return <RedqueenPagination {...props}/>
}
// 按钮
export function Button(props) {
    return <NewButton {...props}/>
}
// tag标签
export function Tag(props) {
    return <NewTag {...props}/>
}
// Input输入框
export function Input(props) {
    return <NewInput {...props}/>
}
export function InputSearch(props) {
    return <NewInputSearch {...props}/>
}
export function TextArea(props) {
    return <NewTextarea {...props}/>
}
//单选框
export function Radio(props) {
    return <NewRadio {...props}/>
}
// 复选框
export function Checkbox(props) {
    return <NewCheckBox {...props}/>
}
export function CheckboxSingle(props) {
    return <NewCheckBoxSingle {...props}/>
}
//徽章
export function Badge(props) {
    return <NewBadge {...props}/>
}

// 全局提示
// let MessageTime = 100;
NewMessage.config({
    duration: 2000
})
export const Message = {
    success: function (content) {
        // Alert.success({
        //     content
        // })
        NewMessage.success({
            icon: <Success/>,
            content,
            className:"redqueenMessage",
            // duration: MessageTime,
        })
    },
    warning: function (content) {
        // Alert.warning({
        //     content
        // })
        NewMessage.warning({
            icon: <Warning/>,
            content,
            className:"redqueenMessage",
            // duration: MessageTime,
        })
    },
    error: function (content) {
        // Alert.error({
        //     content
        // })
        NewMessage.error({
            icon: <Error/>,
            content,
            className:"redqueenMessage",
            // duration: MessageTime,
        })
    },
    info: function (content) {
        NewMessage.info({
            icon: <Info/>,
            content,
            className:"redqueenMessage",
            // duration: MessageTime,
        })
    },
    loading: function (content) {
        NewMessage.error({
            icon: <IconLoading style={{color:'#AD0C24', width:16, height:16,position:'relative', top:-2}}/>,
            content,
            className:"redqueenMessage",
            // duration: MessageTime,
        })
    },
    normal: function (content) {
        NewMessage.normal({
            content,
            className:"redqueenMessage",
            // duration: MessageTime,
        })
    }
}
export const Alert = {
    success: function (content) {
        Alert.close();
        NewMessage.success({
            showIcon:false,
            content: <AlertComponent data={content} type='success'/>,
            // duration: MessageTime,
            className:"redqueenAlert success",
        })
    },
    default: function (content) {
        Alert.close();
        NewMessage.success({
            showIcon:false,
            content: <AlertComponent data={content} type='default'/>,
            // duration: MessageTime,
            className:"redqueenAlert default",
        })
    },
    warning: function (content) {
        Alert.close();
        NewMessage.success({
            showIcon:false,
            content: <AlertComponent data={content} type='warning'/>,
            // duration: MessageTime,
            className:"redqueenAlert warning",
        })
    },
    error: function (content) {
        Alert.close();
        NewMessage.success({
            showIcon:false,
            content: <AlertComponent data={content} type='error'/>,
            // duration: MessageTime,
            className:"redqueenAlert error",
        })
    },
    close: () => {
        NewMessage.clear();
    }
}
//日期选择器
export function DatePicker(props) {
    return <NewDatePicker {...props}/>
}
//日期范围选择器
export function RangePicker(props) {
    return <NewRangePicker {...props}/>
}
// 选择器
export function Select(props) {
    return <NewSelect {...props}/>
}
//switch开关
export function Switch(props) {
    return <NewSwitch {...props}/>
}
//对话弹框
export function Modal(props) {
    return NewModal(props)
}
//Form表单
export function Form(props) {
    return <NewForm autoComplete='off' {...props}/>
}
export function FormItem(props) {
    return <NewFormItem wrapperCol={{ span: 24 }} {...props}/>
}
export function Anchor(props) {
    return <NewAnchor {...props}/>
}
//Link链接
export function Link(props) {
    return <NewLink {...props}/>
}