export default {
    demo: 'Json/index.json',
    /**
     * 公共部分
     */
    //获取密码转译密钥
    passwordPublickKeyHttp: '/common/getPublicKey.json',
    //获取用户信息
    userInfoHttp: '/user/getSimpleUserInfo.json',
    //关闭首次打开弹框
    closeFirstOpenHttp: '/user/closeVersionPrompt.json',
    //获取详细的用户信息
    userInfoDetailHttp:'/user/getUserInfo.json',
    //获取菜单栏
    menuListHttp: '/user/menuList.json',
    //获取手机验证码
    querySimCodeHttp: '/common/getSmsCode2.json',
    changeLanguageTypeHttp: '/common/changeLocale.json',
    /**
     * 登录
     */
    loginHttp:'/ajaxLogin.json',
    logoutHttp:'/logout.json',
    /**
     * 用户中心
     */
    //修改用户信息
    userCenterChangeUserInfoHttp:'/user/changeUserInfo',
    //获取通知设置数据
    userCenterNoticeDataHttp: '/user/getNotifyConfig.json',
    userCenterNoticeChangeDataHttp: '/user/updateNotifyConfig.json',
    //获取情报卡片数据
    userCenterCardDataHttp: '/Json/userRss/userCardList.json',
    //获取数据集合
    userCenterDataHttp: '/Json/userRss/userSuggestCollectionList.json',
    // 开通订阅服务申请
    userCenterServiceApply: '/user/applyOpenSubscription.json',
    /**
     *  漏洞情报
     */
    InfoHoleHotSearchHttp: '/Json/vuln/vulnHotSearch.json',
    infoHoleImportListHttp: '/Json/vuln/importantVulnList.json',
    infoHoleNewListHttp: '/Json/vuln/newVulnList.json',
    infoHoleSearchListHttp: '/Json/vuln/vulnIntelSearch.json',
    infoHoleWangListHttp: '/Json/vuln/relatedTiList.json',
    imfoHoleDetailHttp: '/Json/vuln/vulnIntelDetailsInfo.json',
    imfoHoleDetaiDownloadJsonHttp: '/Json/vuln/vulnIntelDetailsInfoForJsonDownload.json',
    imfoHoleDetaiDownloadStix2Http: '/Json/vuln/vulnIntelDetailsInfoForStix20Download.json',
    imfoHoleDetaiDownloadStix21Http: '/Json/vuln/vulnIntelDetailsInfoForStix21Download.json',
    /**
     * 情报订阅
     */
    //情报市场-情报卡片
    infoMarkeSelectListHttp:'/Json/market/cardMarketCondition.json',
    infoMarketCardListHttp:'/Json/market/cardList.json',
    infoMarketCardDetailHttp: '/Json/market/cardDatail.json',
    infoMarketSubscriptionListHttp: '/Json/market/subscrColIsCheckedList.json',
    infoMarketSubscriptionSubmitHttp:'/Json/market/cardSubscrCollection.json',
    inforMarketApplyHttp:'/market/cardApply.json',
    //情报市场-数据集合
    infoMarketDataSelectListHttp: '/Json/market/suggestCollectionCondition.json',
    infoMarketDataListHttp: '/Json/market/suggestCollectionList.json',
    infoMarketDataDetailHttp: '/Json/market/suggestCollectionDetail.json',
    infoMarketDataApplyHttp: '/market/suggestCollectionApply.json',
    infoMarketDataSubApplyHttp: '/Json/market/subscrSuggestCollection.json',
    //情报订阅
    infoSubCollectionListHttp: '/Json/rss/subscrCollectionList.json',
    infoSubSensorListHttp:'/Json/rss/sensorList.json',
    infoSubDeleteCollectionHttp: '/Json/rss/collectionDel.json',
    infoSubDeleteSensorHttp: '/Json/rss/sensorDel.json',
    infoSubTokenRefreshHttp: '/Json/rss/sensorRefresh.json',
    infoSubModifySensorOfCollectionHttp:'/Json/rss/modifySensorOfCollection.json',
    infoSubCreateSensorHttp: '/Json/rss/sensorCreateSubmit.json',
    infoSubSensorEditDetailHttp: '/Json/rss/sensorEditDetail.json',
    infoSubSensorEditSubmitHttp:'/Json/rss/sensorEditSubmit.json',
    // 集合详情
    infoSubCollectionDetailHttp: '/Json/rss/collectionDetail.json',
    infoSubCardListByCollectionHttp: '/Json/cardRss/cardListByCollection.json',
    infoSubTiListByCollectionHttp: '/Json/rss/tiListConditionByCollection.json',
    infoSubIocListByCollectionHttp: '/Json/rss/iocListConditionByCollection.json',
    infoSubInfoListHttp:'/Json/rss/tiListByCollection.json',
    infoSubIndicatorListtHttp:'/Json/rss/iocListByCollection.json',
    infoSubSensorLisByCollectiontHttp: '/Json/rss/sensorListByCollection.json',
    infoSubSensorDetailHttp:'/Json/rss/sensorDetail.json',
    //创建修改集合
    createCardCollectionConditionListHttp: '/Json/cardRss/cardCollectionCondition.json',
    createCardCollectionCardListHttp: '/Json/cardRss/cardListByCollectionDeal.json',
    createCardCollectionSubmitHttp: '/Json/cardRss/cardCollectionCreateSubmit.json',
    editCardCollectionQueryDetailHttp:'/Json/cardRss/cardCollectionEditDetail.json',
    editCardCollectionSubmitHttp: '/Json/cardRss/cardCollectionEdit.json',
    createTagsCollectionInfoListHttp: '/Json/tagRss/tiListByCreateTagsCollection.json',
    createTagsCommonAndSourceHttp: '/Json/tagRss/quickTagsCondition.json',
    createTagsTagByKeywordHttp: '/Json/tagRss/tagConditionQuery.json',
    createTagsCollectionSubmitHttp: '/Json/tagRss/tagCollectionCreateSubmit.json',
    editTagsCollectionQueryDetailHttp: '/Json/tagRss/tagCollectionEditDetail.json',
    editTagsCollectionInfoListHttp: '/Json/tagRss/tiListByEditTagsCollection.json',
    editTagsCollectionSubmitHttp: '/Json/tagRss/tagCollectionEditSubmit.json',
    // infoMarketDataApplyHttp: '/Json/market/suggestCollectionApply.json',
    infoMarketDataSubApplyHttp: '/Json/market/subscrSuggestCollection.json',
    createBeforeCheckOverLimitHttp: '/Json/rss/collectionOrSensorExist.json',
    createBeforeCheckIsExistHttp: '/Json/rss/collectionNameExist.json',

    //威胁知识库-威胁组织
    organListHttp:'/Json/organ/organList.json',
    organConditionHttp:'/Json/organ/organCondition.json',
    organDetailsInfoStixHttp:'/Json/organ/organDetailsInfoStix.json',
    threatMapStatisticsHttp:'/Json/organ/threatMapStatistics.json',

    /**
     * 消息中心
     */
    //获取未读信息数量
    messageCenterUnreadMessage: '/Json/notice/unreadMessages.json',
    messageCenterNumberByTypeHttp:'/Json/notice/noticeNumOfType.json',
    messageCenterQueryMessageListHttp: '/Json/notice/noticeList.json',
    messageCenterAllIsReadHttp: '/Json/notice/allNoticeRead.json',
    messageCenterNoticeReadHttp: '/Json/notice/noticeRead.json',
    organDetailsIocsGeneralHttp:'/Json/organ/organDetailsIocsGeneral.json',
    organDetailsRelateListHttp:'/Json/organ/organDetailsRelateList.json',
    malwareDetailsInfoStixHttp:'/Json/malware/malwareDetailsInfoStix.json',
    organDetailsDownloadHttp:'/Json/organ/organDetailsDownload.json',
    organDetailsExportIocsHttp:'/Json/organ/organDetailsExportIocs.json',
    toolDetailsInfoStixHttp:"/Json/tool/toolDetailsInfoStix.json",
    //恶意软件
    malwareListHttp:'/Json/malware/malwareList.json',
    malwareConditionHttp:'/Json/malware/malwareCondition.json',
    malwareDetailsDownloadHttp:'/Json/malware/malwareDetailsDownload.json',

    //利用工具
    toolListHttp :'/Json/tool/toolList.json',
    toolConditionHttp:'/Json/tool/toolCondition.json',
    toolDetailsDownloadHttp:'/Json/tool/toolDetailsDownload.json',

    //攻击模式
    attackPatternListHttp:'/Json/attack/attackPatternList.json',
    attackPatternConditionHttp:'/Json/attack/attackPatternCondition.json',
    attackPatternDetailsInfoStixHttp:"/Json/attack/attackPatternDetailsInfoStix.json",
    attackPatternDetailsDownloadHttp:'/Json/attack/attackPatternDetailsDownload.json',

    /* 首页 */
    homeIndexSummarizationData: '/Json/intelligence/homeDataTotal.json',//首页数据汇总
    homeIndexImportInteListHttp:'/Json/intelligence/homeImportantIntell.json',//重点情报列表
    homeIndexHoleIntelListHttp:'/Json/intelligence/homeVulnIntelligence.json',//漏洞情报列表
    homeIndexIocListHttp: '/Json/intelligence/homeIocs.json',//指示器列表
    homeIndexIocLisDownloadtHttp: '/Json/intelligence/homeIocsStix.json',//指示器下载
    homeIndexIocDownloadHttp: '/Json/intelligence/homeIocsStix.json',//指示器下载
    homeIndexSafeInfoListHttp:'/Json/intelligence/homeSafetyInfo.json',//安全资讯列表
    homeIndexDataCollectionHttp: '/Json/intelligence/homeDataList.json',//数据集合列表
    homeIndexReportListHttp: '/Json/intelligence/reserchReport.json',//首页研究报告列表
    homeIndexSearchCriteriaHttp: '/Json/intelligence/homeSearchCondition.json',//首页顶部下拉搜索条件
    homeIndexSearchAddHistoryHttp: '/Json/intelligence/homeAddSearch.json',//首页搜索添加历史搜索记录
    homeIndexSearchDeleteHistoryHttp: '/Json/intelligence/homeClearSearch.json',//历史搜索清空
    homeSecurityInfoListHttp:'/Json/information/informationList.json',//全部安全资讯
    homeSecurityInfoDetailHttp: '/Json/information/informationDetail.json',//资讯详情
    homeSearchGlobalIntellHttp: '/Json/intelligence/globalIntellSearch.json',//全局搜索-情报
    homeSearchGlobalInfoHttp: '/Json/information/globalInformationList.json',//全局搜搜-资讯
    homeSearchGlobalHoleHttp: '/Json/vuln/globalVulnIntelSearch.json',//全局搜搜-漏洞
    homeSearchGlobalOrgHttp: '/Json/organ/organList.json',//全局搜搜-威胁组织
    homeSearchGlobalMalwareHttp: '/Json/malware/malwareList.json',//全局搜搜-恶意软件
    homeSearchGlobalToolHttp: '/Json/tool/toolList.json',//全局搜搜-利用工具
    homeSearchGlobalAttackHttp: '/Json/attack/attackPatternList.json',//全局搜搜-攻击模式
    homeSearchGlobalSafetyHttp: '/Json/information/globalSafety.json',//全局搜搜-安全通告

    // 情报详情
    homeInfoDetailDataHttp: '/Json/intelligence/intelDetailsInfo.json',//情报详情
    homeInfoDownloadHttp: '/Json/intelligence/intelDetailsDownload.json',//情报详情下载
    homeInfoDetailIcoHttp:'/Json/intelligence/intelDetailsIocsGeneral.json',//获取指示器列表
    homeInfoDetailIocDownloadHttp: '/Json/intelligence/intelDetailsExportIocs.json',//指示器下载
    homeInfoDetailInfoDataHttp: '/Json/intelligence/intelDetailsRelateList.json',//关联情报列表

    //高级情报
    homeAdvanceIntellDataHttp:'/Json/intelligence/advancedIntellSearch.json',//高级情报-威胁情报
    homeAdvanceHoleInfoDataHttp: '/Json/vuln/advancedVulnIntelSearch.json',//高级情报-漏洞情报
}