import axios from 'axios'
import eventStation from '@/utils/eventStation'
import loading from '@/redux/actions/loading'
import { Message } from '@/arco'
import qs from 'qs'
// 创建axios实例
const service = axios.create({
  baseURL: process.env.NODE_ENV == 'development' ? '/development/' : '/',
  timeout: 300000
})
// request拦截器
service.interceptors.request.use(config => {
  config.headers['Content-Type'] = 'application/x-www-form-urlencoded'; config.headers['X-Requested-With'] = ' XMLHttpRequest';
  config.headers.Lang = eventStation.lang
  // config.returnAll = config.returnAll
  if (config.loading) {
    eventStation.dispatch(loading(true))
  }
  config.method = config.method || 'POST'
  if (config.method == 'get' || config.method == 'GET') {
    config.params = { ...config.data };
    delete config.data;
  } else if (!eventStation.isLogin) {
    config.data = qs.stringify({ query: JSON.stringify(config.data) })
    // config.data = {query:JSON.stringify(config.data)}
  }
  return config
}, error => {
  Promise.reject(error)
})

// respone拦截器
service.interceptors.response.use(
  (response, a) => {
    const code = response.data.code || 200;
    if (code == 403) {
      eventStation.navigate('/403')
    } else if (code == 404) {
      eventStation.navigate('/404')
    }
    eventStation.dispatch(loading(false))
    const { data } = response;
    const { result: { result, msg } } = data;
    if (response.config.returnAll || eventStation.isLogin) {
      if (result == 0 || result == 1) {
        return data;
      } else {
        throwError()
      }
    } else {
      if (result == 0) {
        return data;
      } else if (result == 1) {//一般错误
        eventStation.navigate('/error', {
          state: {
            message: msg
          }
        })
      } else {
        throwError();
      }
    }
    function throwError() {
      if (result == 2) {//去登录
        let params = sessionStorage.getItem("searchParams");
        params = params ? JSON.parse(params) : null;
        eventStation.navigate('/login', {
          state: {
            path: eventStation.location.pathname,
            data: params
          },
        })
      } else if (result == 3 || result == 4 || result == 5) {//错误提示页
        eventStation.navigate('/error', {
          state: {
            message: msg,
            type: "lock"
          }
        })
      } else if (result == 6) {//输入内容含有非法字符-回到主页
        Message.error(msg);
        eventStation.navigate('/home')
      } else if (result == 7) {//输入内容含有非法字符-后退一页
        Message.error(msg);
        eventStation.navigate(-1)
      } else if (result == 8) {//提示页-链接已失效
        Message.error(msg);
      }
    }
  },
  error => {
    eventStation.dispatch(loading(false))
    // 错误处理
    return Promise.reject(error)
  }
)

export default service
